import { createRoot } from "react-dom/client";
import { StrictMode, lazy } from "react";
import { kcContext } from "./KcContext";

const KcApp = lazy(() => import("./KcApp"));
if (kcContext === undefined) {
  throw new Error(
    "This app is a Keycloak theme" +
      "It isn't mean to be deployed outeside of Keycloak"
  );
}

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <KcApp kcContext={kcContext} />
  </StrictMode>
);
